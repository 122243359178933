import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NetworkCallsService } from "./network-calls.service";
import { RequestInterceptorService } from "./request-interceptor.service";
import { LoadingService } from './loading.service';
@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    NetworkCallsService,
    LoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
  ],
  entryComponents: [],
})
export class SharedservicesModule { 
  static forRoot(): ModuleWithProviders<SharedservicesModule> {
    return {
      ngModule: SharedservicesModule,
      providers: [ NetworkCallsService, LoadingService],
    };
  }
}
