import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpEvent,
  HttpParams,
} from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NetworkCallsService {
  base_url = environment.base_url;
  
  constructor(public http: HttpClient) { 
  }

  get(apiurl: string) {
    
    let url = this.base_url + apiurl;
    let headerJson={
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("authTokenRMS")

    }
    let headers = new HttpHeaders(headerJson);
    let options = {
      headers: headers
    };
    return this.http.get<Object>(url, options);
  }

  post(apiurl: string, body: any) {
    
    let url = this.base_url + apiurl;
    let headerJson={
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("authTokenRMS")
    }
    let headers = new HttpHeaders(headerJson);
    let options = {
      headers: headers
    };
    return this.http.post<Object>(url, body, options);
  }
  postForm(apiurl: string, body: any) {
    
    let url = this.base_url + apiurl;
    let headerJson={
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("authTokenRMS")
    }
    let headers = new HttpHeaders(headerJson);
    let options = {
      headers: headers
    };
    return this.http.post<Object>(url, body, options);
  }

  put(apiurl: string, body: any) {
    
    let url = this.base_url + apiurl;
    let headerJson={
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("authTokenRMS")
    }
    let headers = new HttpHeaders(headerJson);
    let options = {
      headers: headers
    };
    return this.http.put<Object>(url, body, options);
  }
  delete(apiurl: string) {
    
    let url = this.base_url + apiurl;
    let headerJson={
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("authTokenRMS")
    }
    let headers = new HttpHeaders(headerJson);
    let options = {
      headers: headers
    };
    return this.http.delete<Object>(url, options);
  }

  login(apiurl: string, body: any) {
    let url = this.base_url + apiurl;
    let headerJson={
      'Accept': 'application/json',
    }
    let headers = new HttpHeaders(headerJson);
    let options = {
      headers: headers
    };
    return this.http.post<Object>(url, body, options);
  }



}
