import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../sharedservices/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  loading = true;

  constructor(private loadingService:LoadingService) { }

  ngOnInit(): void {
    this.loadingService.subsribeValue().subscribe(
      (data)=>{
        this.loading=data;
      }
    )
  }

}
